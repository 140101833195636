















































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DateUtil from 'global-ui/packages/utils/DateUtil';
import FormConfig from '@/components/form-design/config/form-config.vue';
import UserEdit from '@/views/organization/components/user-edit.vue';
import page from '@/mixins/page';
import { FlowModel } from '@/models/flow/FlowModel';
import { getUserPageData } from '@/api/system/UserApi';
import { getDeptListData } from '@/api/system/OrganizationApi';

@Component({
  name: 'FormList',
  components: {
    FormConfig,
    UserEdit
  }
})
export default class Flow extends mixins(page) {
  private DateUtil: any = DateUtil;
  private formListData: FlowModel[] = [];
  private loadingOuter: boolean = false;
  private formSearchModel: any = { name: '' };

  private showUserEditModal: boolean = false;
  private selectedUserId: string = '';
  private editTitle:string = "新增用户";

  private groupTreeData: any[] = [];

  private groupTreeNormalizer: any = function (node) {
    return {
      id: node.id,
      label: node.name,
      children: node.children
    };
  };

  mounted() {
    this.getDeptList();
    this.getFormPage();
  }

  // 获取表单列表
  getFormPage() {
    let params = {
      name: this.formSearchModel.name,
      groupId: this.formSearchModel.groupId,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.loadingOuter = true;
    getUserPageData(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.formListData = res.data.list;
          this.total = res.data.total;
        }
      })
      .finally(() => {
        this.loadingOuter = false;
      });
  }

  handleSearch() {
    this.pageIndex = 1;
    this.getFormPage();
  }

  handleSearchReset() {
    this.pageIndex = 1;
    this.formSearchModel = { name: '' };
    this.getFormPage();
  }

  // 返回上一页
  back() {
    this.$router.go(-1);
  }

  intoForm(row) {
    let routeUrl = this.$router.resolve({
      path: '/form/dataviewer',
      query: { formId: row.id }
    });
    window.open(routeUrl.href, '_blank');
  }

  /**
   * 跳转至某页
   */
  changePage(page) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getFormPage();
  }

  // 加载部门列表
  getDeptList() {
    // 请求参数
    let params = {
      parentId: '-1'
    };
    // 调用接口
    getDeptListData(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.groupTreeData = res.data;
      }
    });
  }

  // 打开新增页面
  handleAdd() {
    this.selectedUserId = "";
    this.showUserEditModal = true;
  }

  // 打开编辑页面
  handleEdit(user) {
    this.showUserEditModal = true;
    this.selectedUserId = user.id;
    this.editTitle = "编辑用户 " + user.name;
  }
}
