

























































































import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { UserModel, UserModelData } from '@/models/organization/UserModel';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
import { saveUser, getUserSingle } from '@/api/system/UserApi';

@Component({
  name: 'UserEdit'
})
export default class UserEditForm extends Vue {
  // ref
  @Ref() readonly formRef;
  // 用户id
  @Prop({ default: '' }) userId!: string;

  @Emit('save')
  emitSave() {}

  @Emit('cancel')
  emitCancel() {}

  userModelData: UserModel = UserModelData();
  // 表单加载状态
  formLoading: boolean = false;
  // 提交加载状态
  loading: boolean = false;
  // 表单验证规则
  formRules: Record<string, any> = {
    name: {
      required: true,
      message: this.$t('lang_please_enter_the_organization_group_name'),
      trigger: 'blur'
    }
  };

  private fileUploadAction: string = '/river-fs/file/create?token=' + TokenUtil.getToken();
  private signUrl: string = '';

  created() {
    this.userModelData.id = this.userId;
    if (this.userId && this.userId != '') {
      getUserSingle(this.userId).then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.userModelData = res.data;
          if (res.data.sign && res.data.sign != '') {
            this.signUrl = '/river-fs/file/view?fileId=' + res.data.sign + '&token=' + TokenUtil.getToken();
          }
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }
  /**
   * 保存表单
   */
  saveUser() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      saveUser(this.userModelData)
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.emitSave();
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
          this.loading = false;
        });
    });
  }

  cancelForm() {
    this.loading = false;
    this.emitCancel();
  }

  beforeAvatarUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJPG) {
      this.$message.error('上传头像图片只能是 JPG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传头像图片大小不能超过 2MB!');
    }
    return isJPG && isLt2M;
  }

  handleAvatarSuccess(res, file) {
    if (res.data.sign && res.data.sign != '') {
      this.signUrl = '/river-fs/file/view?fileId=' + res.data.sign + '&token=' + TokenUtil.getToken();
    }
    this.userModelData.sign = res.data.id;
  }
}
