var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-form" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.userModelData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-position": "right",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("lang_account"), prop: "account" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.userModelData.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.userModelData, "account", $$v)
                          },
                          expression: "userModelData.account",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_name"), prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.userModelData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.userModelData, "name", $$v)
                          },
                          expression: "userModelData.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.userModelData.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.userModelData, "sex", $$v)
                            },
                            expression: "userModelData.sex",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "男", value: "0" },
                          }),
                          _c("el-option", {
                            attrs: { label: "女", value: "1" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "证件类型", prop: "idType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.userModelData.idType,
                            callback: function ($$v) {
                              _vm.$set(_vm.userModelData, "idType", $$v)
                            },
                            expression: "userModelData.idType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "身份证", value: "0" },
                          }),
                          _c("el-option", {
                            attrs: { label: "护照", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "港澳通行证", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "证件号码", prop: "idcard" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.userModelData.idcard,
                          callback: function ($$v) {
                            _vm.$set(_vm.userModelData, "idcard", $$v)
                          },
                          expression: "userModelData.idcard",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "mobilePhone" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.userModelData.mobilePhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.userModelData, "mobilePhone", $$v)
                          },
                          expression: "userModelData.mobilePhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "固定电话", prop: "tel" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.userModelData.tel,
                          callback: function ($$v) {
                            _vm.$set(_vm.userModelData, "tel", $$v)
                          },
                          expression: "userModelData.tel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱", prop: "email" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.userModelData.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.userModelData, "email", $$v)
                          },
                          expression: "userModelData.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "微信", prop: "wechartNo" } },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.userModelData.wechartNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.userModelData, "wechartNo", $$v)
                          },
                          expression: "userModelData.wechartNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "签字章", prop: "sign" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.fileUploadAction,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                  },
                },
                [
                  _vm.signUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.signUrl },
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.cancelForm } }, [
                _vm._v(_vm._s(_vm.$t("lang_cancel"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.saveUser()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.loading
                        ? _vm.$t("lang_submit")
                        : _vm.$t("lang_confirm")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "user-form-footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }