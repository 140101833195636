
import { DatumModel } from '../common/DatumModel';
export interface UserModel extends DatumModel {

    name: string;
    account: string;
    personId: string;
    password: string;
    sign: string;
    disabled: boolean;

    // extends
    sex: string;
    idType:string;
    idcard: string;
    wechartNo: string;
    mobilePhone: string;
    tel: string;
    email: string;
}

export const UserModelData = (): UserModel => {
    return {
        name: '',
        account: '',
        personId: '',
        password: '',
        sign: '',
        disabled: false,
        sex: '',
        idType: '',
        idcard: '',
        wechartNo: '',
        mobilePhone: '',
        tel: '',
        email: '',
        id: ''
    }
};
